import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import RealisationPost from '../components/RealisationPost'
import SEO from '../components/SEO'

class Realisations extends React.Component {
  render() {
    const { realisationsListing } = this.props.data
    
    return (
      <React.Fragment>
        <SEO 
          title={`Projets`} 
          desc={`Tour d'horizon des réalisations`}
          pathname={this.props.path} 
        />
        <Header {...this.props} />
        <MainWrapper>
          {realisationsListing.edges.map((item, index) => {
            return <RealisationPost {...item} key={`post-${index}`} />
          })}
        </MainWrapper>
      </React.Fragment>
    )
  }
}

export default Realisations
export const pageQuery = graphql`
  query RealisationsListing {
    realisationsListing: allPrismicRealisation {
      edges {
        node {
          id
          data {
            title {
              html
            }
            credits {
              html
            }
            video {
              provider_name
              provider_url
              embed_url
            }
          }
        }
      }
    }
  }
`