import React from 'react'
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { PostContainer } from '../containers/Post'
import { VideoContainer } from '../containers/Media'
import { getVideoId } from '../utils/mediaHelpers'

const videoOpts = {
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

const RealisationPost = ({ node: { data } }) => {
  const { credits, title } = data;
  
  const video = {
    id: getVideoId(data.video.provider_name, data.video.embed_url),
    type: data.video.provider_name
  };

  return (
    <React.Fragment>
      <PostContainer className="listing">
        <h2 className="title" dangerouslySetInnerHTML={{ __html: title.html }}></h2>
        <div className="content" dangerouslySetInnerHTML={{ __html: credits.html }}></div>
        <React.Fragment>
          {
            video.type === 'YouTube' ? (
              <VideoContainer>
                <YouTube 
                  videoId={video.id} 
                  opts={videoOpts} 
                />
              </VideoContainer>
            ) : (
              <VideoContainer>
                <Vimeo video={video.id} />
              </VideoContainer>
            )
          }
        </React.Fragment>
      </PostContainer>
    </React.Fragment>
  )
}

export default RealisationPost;